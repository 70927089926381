@font-face {
  font-family: 'Geo';
  src: url('geomatrix.otf') format('opentype'), url('geomatrix.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Geo',sans-serif;
  letter-spacing: 1px;
  color: #3e3e3e;
}

#header{
  display: flex;
  text-align: center;
  font-size: 13px;
  padding-top: 5px;
  flex-flow: column;
  align-items: center;
  background: rgb(147,147,147);
  background: linear-gradient(90deg, rgba(147,147,147,1) 38%, rgba(62,62,62,1) 100%);
  color: #dfdfdf;
}

#header-menu{
  display: flex;
  width: 100%;
  justify-content: center;
}

#header-nome-site{
  text-decoration: underline overline;
}

#header-br{
  display: none;
}

.header-link{
  padding: 5px 10px;
  font-size: 16px;
  margin-top: 12px;
  color: #dfdfdf;
  text-decoration: none;
  text-align: center;
  font-style: normal;
}

.header-link:hover{
  background-color: rgba(0,0,0,.1);
}

.header-link.active{
  background-color: rgba(0,0,0,.1);
}

.medidas_facil{
  width: 573px;
  margin: auto;
  display: flex;
  flex-flow: column;
  place-items: center;
}

.medida_conteudo_origem{
  text-align: end;
}

.medida_conteudo_destino{
}

.medida_conteudo{
  width: 100%;
}

.medida_box{
  display: flex;
  place-items: center;
  place-content: center;
  width: 100%;
  margin-top: 20px;
}

.medida_box.hide{
  display: none;
}

.titulos{
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 22px;
  justify-content: space-between;
  background: rgb(223,223,223);
  background: linear-gradient(90deg, rgba(223,223,223,1) 17%, rgba(94,94,94,1) 88%);
}

.titulos span{
  padding: 0px 10px;
}

.olho_conteudo{
  width: 26px;
  height: 26px;
  background: transparent url(./img/olho-aberto-branco.png) no-repeat center;
  background-size: 26px;
}

.olho_conteudo.hide{
  background: transparent url(./img/olho-fechado-branco.png) no-repeat center;
  background-size: 26px;
}

input, select{
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  font-family: 'Geo',sans-serif;
  letter-spacing: 1px;
  width: 250px;
  box-sizing: content-box;
  padding: 12px 5px;
  border: 1px solid #b9b9b9;
  color: #3e3e3e;
  border-radius: 0px;
}

.div_opcoes_medidas{
  position: relative;
  display: inline-block;
}

.div_opcoes_medidas:after {
  content: '\0025BE';
  color: #3e3e3e;
  right: 5px;
  padding: 5px 0px 0px 3px;
  position: absolute;
  pointer-events: none;
}

input:focus-visible, select:focus-visible{
  outline: none;
}

.input_text_medida{
  border-top: none;
  font-size: 28px;
  text-align: center;
  margin-top: -3px;
}

.input_text_medida:disabled {
  background-color: #ddd;
}

.opcoes_medidas{
  font-size: 14px;
  padding: 6px 5px;
}

.equals{
  padding: 0px 15px;
  font-size: 2em;
}

#link{
  margin-top: 20px;
}

a{
  font-style: italic;
}

#medidas_informacoes{
  color: #555;
  padding: 0px 5px;
}

#medidas_informacoes img{
  display: block;
  margin: auto;
  max-width: 550px;
}

#medidas_informacoes span{
  margin-left: 15px;
}

#medidas_informacoes p{
  text-align: justify;
}

.informacoes_calculo{
  background-color: #333;
  color: #ddd;
  padding: 5px;
  line-height: 34px;
}

@media only screen and (max-width: 768px){
  .titulos{
    font-size: 18px;
  }
}

@media only screen and (max-width: 568px){
  #header, .medidas_facil{
    width: 100%;
    min-width: 360px;
  }

  #header-br{
    display: block;
  }
  
  .medida_box{
    flex-flow: column;
  }

  .titulos{
    font-size: 16px;
  }

  #medidas_informacoes img{
    max-width: 400px;
  }
}

